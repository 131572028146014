import React from 'react';

const TipFaucet = () => {
  return (
    <div>
      <h3>Send Tips To <span style={{ color: '#ff652f' }}>PondGames.eth</span></h3>
    </div>
  );
};

export default TipFaucet;